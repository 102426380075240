<template>
    <div>
        <headTitle :isMain="3"/>
        <img class="mobile-example-headImg" src="../../assets/image/mobile/02-3APS高级排程.png"/>
        <div class="mobile-head-explain">
            <div class="mobile-head-explain-title">APS高级排程</div>
            <div class="mobile-head-explain-content">运输、生产整体规划，需求、成本最优决策</div>
        </div>
        <div class="mobile-head-catalogue" >
            <div :class="{'title1':true,'ischecked':checkIndex==1} " @click="(checkIndex=1)"><span>业务痛点</span></div>
            <div :class="{'title1':true,'ischecked':checkIndex==2} " @click="(checkIndex=2)"><span>解决方案</span></div>
            <div :class="{'title1':true,'ischecked':checkIndex==3} " @click="(checkIndex=3)"><span>方案收益</span></div>
        </div>
        <div v-if="(checkIndex==1)" class="mobile-content-box1">
            <div class="ware-title" style="margin-top:6.2rem">成本控制</div>
            <div class="ware-content1" style="margin-top:1rem">产品大规模的运输路线组合，运输成本存在可降空间;<br/>
                各工厂生产成本不同，合理规划生产计划，生产成本可压缩;</div>
            <div class="ware-title" style="margin-top:4.8rem">需求满足</div>
            <div class="ware-content1" style="margin-top:1rem">客户DOI和工厂DOI满足程度不高，成本相同的情况下，存在可提升空间;<br/>
                中小客户需求或大客户小品类的需求时常无法得到满足;</div>
            <div class="ware-title" style="margin-top:4.8rem">排产效率</div>
            <div class="ware-content1" style="margin-top:1rem">常规系统排产，运算速度较慢且无法并发执行；手工排产，仅凭经验计划准确率低;<br/>
                系统功能局限，缺乏对目标/参数不合理的分析预警功能;</div>
            <div class="ware-title" style="margin-top:4.8rem">业务运作</div>
            <div class="ware-content1" style="margin-top:1rem;margin-bottom: 5rem;">通过调整入参来改变计划排产结果，不可预知的因素多，排程结果差异大;<br/>
                主计划的频繁调整，会影响供应链后续流程，形成牛尾效应。</div>
        </div>
        <div v-else-if="(checkIndex==2)" class="mobile-content-box1">
            <img class="ware-img" style="margin-bottom:5rem" src="../../assets/image/example/02-4解决方案.png"/>
        </div>
        <div v-else-if="(checkIndex==3)" class="mobile-content-box1">
            <div class="box2-2">
                <div class="card">
                    <img src="../../assets/image/example/02-4成本节约.svg"/>
                    <div class="title2-1">成本节约</div>
                    <div class="title2-2">自动规划产品生产和运输线路最优组合，节省企业生产成本和运输成本。</div>
                </div>
                <div class="card">
                    <img src="../../assets/image/example/02-4需求满足.svg"/>
                    <div class="title2-1">需求满足比例提升</div>
                    <div class="title2-2">合理规划产品的生产和发运量，提升客户DOI以及工厂DOI满足比例。</div>
                </div>
                <div class="card">
                    <img src="../../assets/image/example/02-4排产效率.svg"/>
                    <div class="title2-1">排产效率改善</div>
                    <div class="title2-2">模型运算速度秒化级提升，同时配合入参校验预警，避免多项入参数据冲突导致排产结果异常，大幅提升排产整体效率。</div>
                </div>
                <div class="card">
                    <img src="../../assets/image/example/02-4排产效率.svg"/>
                    <div class="title2-1">业务运作优化</div>
                    <div class="title2-2">通过调节需求满足和成本的权重系数，找到最适合企业的一个需求与成本的平衡点，带动整个供应链最优化。</div>
                </div>
            </div>
            <img class="ware-img2" style="margin-bottom:5rem" src="../../assets/image/example/02-4方案收益.png"/>
        </div>
        <mobileFoot/>
    </div>
</template>

<script>
import headTitle from '../../components/mobile/head-title.vue'
import mobileFoot from '../../components/mobile/mobile-foot.vue'
import mobileCarousel from '../../components/mobile/mobile-carousel.vue'
import Swiper from "swiper";
export default {
    name: 'BizfocusIndexMobileIndex',
    components:{headTitle,mobileCarousel,mobileFoot},
    data() {
        return {
            checkIndex: 1,
            showDescription:false,
            box2ImageList:[
                {
                    index:0,
                    urlImg:require("../../assets/image/index/01-15GF整合数据分析系统.png"),
                    title:'5GF整合数据分析系统',
                    content:'全球某知名日用消费品公司',
                    route:'/selected-5GF'
                },
                {
                    index:1,
                    urlImg:require("../../assets/image/index/01-1数字化产线.png"),
                    title:'数字化产线', //右转是0
                    content:'打造透明、高效、稳定的数字化生产管理平台',
                    route:'/selected-line'
                },
                {
                    index:2,
                    urlImg:require("../../assets/image/index/01-1市场渗透率分析.png"),
                    title:'市场渗透率分析',
                    content:'全球某化妆品巨头',
                    route:'/selected-marykay'
                },
                {
                    index:3,
                    urlImg:require("../../assets/image/index/01-1智能仓储.png"),
                    title:'智能仓储',
                    content:'布局原料赋码，助力品质生产',
                    route:'/selected-warehousing'
                },
                {
                    index:4,
                    urlImg:require("../../assets/image/index/01-1Dashboard数据实施.png"),
                    title:'Dashboard数据实施',
                    content:'全球某知名乳制品公司',
                    route:'/selected-dashboard'
                },
                {
                    index:5,
                    urlImg:require("../../assets/image/index/01-1商情诊断管理分析.png"),
                    title:'商情诊断管理分析',
                    content:'全球某知名日用消费品公司',
                    route:'/selected-business'
                },
                {
                    index:6,
                    urlImg:require("../../assets/image/index/01-1iDAS整合数据分析系统.png"),
                    title:'iDAS整合数据分析系统', //左转是0
                    content:'韩国某知名化妆品集团',
                    route:'/selected-iDAS'
                },

            ],
        };
    },

    mounted() {
    },

    methods: {
        jumpMobileRoute(route){
            this.$router.push(route)
            console.log(route)
        }
    },
};
</script>

<style lang="less" scoped>
.description{
    display: flex;
    width: 66.5rem;
    margin: auto;
    margin-top: 6rem;
    .title1{
        font-size: 2.8rem;
        font-family: 'CN_Medium';
        font-weight: 500;
        color: #11A84F;
        line-height: 4.2rem;
    }
    .title2{
        font-size: 2.8rem;
        font-family: 'CN_Normal';
        font-weight: 400;
        color: #11A84F;
        line-height: 4.2rem;
        margin-left: 70%;
    }
}
.description-box{
    max-width: 66.5rem;
    margin: auto;
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #333333;
    line-height: 5rem;
}
.mobile-head-catalogue{
    width: 100%;
    height: 9rem;
    display: flex;
    background: #F8F8F8;
    .title1{
        width: 20rem;
        height: 9rem;
        display: flex;
        text-align: center;
        align-items: center;
        span{
            font-size: 2.8rem;
            font-family: 'CN_Medium';
            font-weight: 500;
            color: #4F4F4F;
            line-height: 2rem;
            margin: auto;
        }
    }
    .ischecked{
        background: #EDEDED;
        border-bottom: 2px solid #11A84F;
    }
}
.mobile-content-box1{
    width: 66rem;
    margin: auto;
    margin-top: 3rem;
}
.ware-content1{
    max-width: 66rem;
    // height: 10.6rem;
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #333333;
    line-height: 4rem;
}
.ware-title{
    font-size: 2.8rem;
    font-family: 'CN_Medium';
    font-weight: 500;
    color: #000000;
    line-height: 4.2rem;
}
.ware-box1{
    width: 66.8rem;
    height: 10rem;
    border: 1px solid #CCCCCC;
    margin: auto;
    margin-top: 1.3rem;
    display: flex;
    .title1{
        width: 58.8rem;
        height: 4rem;
        font-size: 2.8rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #666666;
        line-height: 4rem; 
        margin: auto; 
        align-items: center;
    }
}
.ware-img{
    width: 100%;
    height: auto;
    margin-top: 5rem;
    margin-bottom: 5rem;
}
.ware-img2{
    width: 100%;
    height:auto;
    // height: 63.3rem;
    margin: 5rem 0 0 0rem;
    display: flex;
}
.box2-2{
    width: 80%;
    // height: 36rem;
    margin-bottom: 6rem;
    margin: auto;

    /*设置当前元素为flex模式*/
    display: flex;
    /*行元素默认不折行，设置为折行*/
    flex-wrap: wrap;
    justify-content: space-between;
    .card{
        width: 25.6rem;
        height: 36rem;
        margin-top: 2rem;
        background: #FFFFFF;
        box-shadow: 0px 0px 12px 0px rgba(233,233,233,0.6);
        img{
            width: 6.4rem;
            height: 6.4rem;
            margin: 5rem 0 0 9.6rem;
        }
        .title2-1{
            height: 2rem;
            font-size: 2rem;
            font-family: 'CN_Medium';
            font-weight: 500;
            color: #000000;
            line-height: 3rem;

            text-align: center;
            margin-top: 2rem;
            margin-bottom: 0.8rem;
        }
        .title2-2{
            width: 22.4rem;
            font-size: 1.6rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #4F4F4F;
            line-height: 3rem;

            margin: auto;
            margin-top: 1.5rem;
            text-align: justify;
        }
    }
}
</style>